<template>
  <div id="Brand">
    <router-view></router-view>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
export default {
  name: "brand",
  components: {
    Breadcrumb,
  },
};
</script>


<style lang="scss" scoped>
</style>
<style>
#Brand {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
}
</style>